import Button, { ButtonVariants } from '@/components/atoms/Button/Button';
import Accordion from '@/components/molecules/Accordion';
import { DropdownMenuOptionType } from '@/components/molecules/DropdownMenu';
import { EVSavingsToggle } from '@/components/molecules/EVSavingsToggle/EVSavingsToggle';
import { useVehicleFilterSettingsContext } from '@/context/VehicleFilterSettings';
import { BodyTypesByMakes } from '@/lib/schema/shared/types';
import Badge, { BadgeVariants } from '@/components/molecules/Badge';
import { MapPinIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { FilterChip, FilterType } from '@/types/filters';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { SearchFilterBodyTypeSection } from '../SearchFilterBodyTypeSection/SearchFilterBodyTypeSection';
import {
  SearchFilterChipsSection,
  SearchFilterChipsSectionProps,
} from '../SearchFilterChipsSection/SearchFilterChipsSection';
import { SearchFilterConditionAndFuelSection } from '../SearchFilterConditionAndFuelSection/SearchFilterConditionAndFuelSection';
import {
  SearchFilterControls,
  SearchFilterControlsProps,
} from '../SearchFilterControls/SearchFilterControls';
import { SearchFilterEVSavingsSection } from '../SearchFilterEVSavingsSection/SearchFilterEVSavingsSection';
import { SearchFilterFeatureSection } from '../SearchFilterFeatureSection/SearchFilterFeatureSection';
import { SearchFilterGeneralInfoSection } from '../SearchFilterGeneralInfoSection/SearchFilterGeneralInfoSection';
import { SearchFilterPriceSection } from '../SearchFilterPriceSection/SearchFilterPriceSection';
import { SearchFilterSellerTypeSection } from '../SearchFilterSellerTypeSection/SearchFilterSellerTypeSection';

export interface SearchFiltersProps
  extends Omit<
      SearchFilterChipsSectionProps,
      | 'handleUpdateMake'
      | 'makesToModels'
      | 'setZipCode'
      | 'filterChips'
      | 'handleUpdateFilter'
    >,
    Omit<SearchFilterControlsProps, 'setZipCode' | 'zipCode'> {
  makes: DropdownMenuOptionType[];
  features: DropdownMenuOptionType[];
  onSearch: () => void;
  setIsListedPrice: (isListedPrice: boolean) => void;
  isListedPrice: boolean;
  makesBodyTypes: BodyTypesByMakes | null;
  models: { byMake: Record<string, string[]>; flat: string[] };
  filterChipData: {
    values: string[];
    kind: keyof FilterType;
  }[];
}

type ExpandedKey =
  | 'Location'
  | 'Make and Model'
  | 'Price'
  | 'Condition and Mileage'
  | 'Feature'
  | 'Seller type'
  | 'Body type';

const SearchFilters: React.FC<SearchFiltersProps> = ({
  filters,
  makes,
  features,
  clearFilters,
  updateFilter,
  onSearch,
  isListedPrice,
  setIsListedPrice,
  setIsGridView,
  isGridView,
  onSortChange,
  sort,
  data,
  makesBodyTypes,
  models,
  filterChipData,
}) => {
  const { settings: vehicleFilterSettings } = useVehicleFilterSettingsContext();
  const filterChipsRef = useRef<HTMLDivElement>(null);

  const initialExpandedState = {
    Location: true,
    'Make and Model': false,
    Price: false,
    'EV Savings': true,
    'Condition and Mileage':
      filters.condition !== '' ||
      filters.fuelType !== '' ||
      filters.maxMileage !== '',
    Feature: filters.features.length > 0,
    'Seller type': filters.dealer_group !== '',
    'Body type': filters.body.length > 0,
  };

  const [expandedStates, setExpandedStates] = useState(
    () => initialExpandedState
  );
  useEffect(() => {
    if (vehicleFilterSettings.evSavings === false) {
      setExpandedStates((prev) => ({
        ...prev,
        'EV Savings': false,
      }));
    } else if (vehicleFilterSettings.evSavings === true) {
      setExpandedStates((prev) => ({
        ...prev,
        'EV Savings': true,
      }));
    }
  }, [vehicleFilterSettings]);
  const [zipCode, setZipCode] = useState<string>('');

  const numberOfChips = useMemo(
    () => ({
      Location: 0,
      'Make and Model':
        filters.make.length +
        filters.model.length +
        (filters.year_start ? 1 : 0) +
        (filters.year_end ? 1 : 0),
      Price: (filters.price_start ? 1 : 0) + (filters.price_end ? 1 : 0),
      'EV Savings': 0,
      'Condition and Mileage':
        (filters.condition ? filters.condition.split(',').length : 0) +
        (filters.fuelType ? filters.fuelType.split(',').length : 0) +
        (filters.maxMileage ? 1 : 0),
      Feature: filters.features.length,
      'Seller type': filters.dealer_group
        ? filters.dealer_group.split(',').length
        : 0,
      'Body type': filters.body.length,
      vector_search: filters.vector_search ? 1 : 0,
    }),
    [filters]
  );

  const handleUpdateMake = useCallback(
    (newMakes: string[]) => {
      const newModelList = Object.keys(models.byMake)
        .filter((make) => newMakes.includes(make))
        .map((make) => models.byMake[make])
        .flat();

      updateFilter({
        fields: {
          make: newMakes,
          model: filters.model.filter((modelName) =>
            newModelList.includes(modelName)
          ),
        },
      });
    },
    [filters.model, models.byMake, updateFilter]
  );

  const handleUpdateModel = useCallback(
    (newModel: string[]) => {
      updateFilter({
        fields: {
          model: newModel,
        },
      });
    },
    [updateFilter]
  );

  const filtersAccordionGroup = [
    {
      title: 'Location',
      children: (
        <SearchFilterControls
          filters={filters}
          updateFilter={updateFilter}
          setIsGridView={setIsGridView}
          isGridView={isGridView}
          setZipCode={setZipCode}
          zipCode={zipCode}
        />
      ),
      condition: true,
      expanded: expandedStates.Location,
      numberOfChips: numberOfChips.Location,
    },
    {
      title: 'Make and Model',
      children: (
        <SearchFilterGeneralInfoSection
          updateFilter={updateFilter}
          filters={filters}
          makes={makes}
          models={models.flat.map((m) => ({ text: m, value: m }))}
          handleUpdateMake={handleUpdateMake}
          handleUpdateModel={handleUpdateModel}
        />
      ),
      condition: true,
      expanded: expandedStates['Make and Model'],
      numberOfChips: numberOfChips['Make and Model'],
    },
    {
      title: 'Price',
      children: (
        <SearchFilterPriceSection
          updateFilter={updateFilter}
          filters={filters}
          isListedPrice={isListedPrice}
          setIsListedPrice={setIsListedPrice}
        />
      ),
      condition: true,
      expanded: expandedStates.Price,
      numberOfChips: numberOfChips.Price,
    },
    {
      title: 'EV Savings',
      children: <SearchFilterEVSavingsSection />,
      condition: true,
      expanded: expandedStates['EV Savings'],
      numberOfChips: numberOfChips['EV Savings'],
    },
    {
      title: 'Condition and Mileage',
      children: (
        <SearchFilterConditionAndFuelSection
          updateFilter={updateFilter}
          filters={filters}
        />
      ),
      condition: true,
      expanded: expandedStates['Condition and Mileage'],
      numberOfChips: numberOfChips['Condition and Mileage'],
    },
    {
      title: 'Feature',
      children: (
        <SearchFilterFeatureSection
          updateFilter={updateFilter}
          filters={filters}
          features={features}
        />
      ),
      condition: process.env.NEXT_PUBLIC_ENABLE_BODY_FEATURE_FILTER === 'true',
      expanded: expandedStates.Feature,
      numberOfChips: numberOfChips.Feature,
    },
    {
      title: 'Seller type',
      children: (
        <SearchFilterSellerTypeSection
          updateFilter={updateFilter}
          filters={filters}
        />
      ),
      condition: true,
      expanded: expandedStates['Seller type'],
      numberOfChips: numberOfChips['Seller type'],
    },
  ];
  if (makesBodyTypes !== null) {
    filtersAccordionGroup.push({
      title: 'Body type',
      children: (
        <SearchFilterBodyTypeSection
          filters={filters}
          updateFilter={updateFilter}
          makesBodyTypes={makesBodyTypes}
        />
      ),
      condition: true,
      expanded: expandedStates['Body type'],
      numberOfChips: numberOfChips['Body type'],
    });
  }

  const handleSetExpanded = (key: ExpandedKey) => {
    setExpandedStates((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  const totalFilterChips = useMemo(() => {
    const totalChips = Object.values(numberOfChips).reduce(
      (acc, curr) => acc + curr,
      0
    );
    return totalChips;
  }, [numberOfChips]);

  const handleUpdateFilter = useCallback(
    (filterName: keyof FilterType, filterItem: string) => {
      const filterList = filters[filterName];
      if (filterName === 'vector_search') {
        updateFilter({
          fields: {
            [filterName]: '',
          },
        });
        return;
      }
      if (filterName === 'price_end' || filterName === 'price_start') {
        updateFilter({
          fields: {
            [filterName]: '',
          },
        });
        return;
      }
      if (filterName === 'postal') {
        setZipCode('');
      }
      let updatedFilterList;
      if (Array.isArray(filterList)) {
        updatedFilterList = filterList.filter((item) => item !== filterItem);
      } else if (typeof filterList === 'string') {
        updatedFilterList = filterList
          .split(',')
          .filter((item) => item !== filterItem)
          .join(',');
      } else {
        updatedFilterList = filterList;
      }
      updateFilter({
        fields: {
          [filterName]: updatedFilterList,
        },
      });
    },
    [filters, updateFilter, setZipCode]
  );

  const createFilterChips = useCallback(
    (filterList: string[], filterName: keyof FilterType) => {
      const filterValue = filterName;
      let chipVariant = BadgeVariants.Blue;
      if (filterValue === 'vector_search') {
        chipVariant = BadgeVariants.Gray;
      } else if (filterValue === 'price_start' || filterValue === 'price_end') {
        chipVariant = BadgeVariants.Dark;
      }
      return filterList.map((filterItem) => ({
        filterName: filterValue,
        label: filterItem,
        variant: chipVariant,
        onClose: () => {
          if (filterChipsRef.current) {
            filterChipsRef.current.style.height = 'fit-content';
          }
          if (filterName === 'make') {
            handleUpdateMake(filterList.filter((item) => item !== filterItem));
          } else {
            handleUpdateFilter(filterName, filterItem);
          }
        },
      }));
    },
    [handleUpdateFilter, handleUpdateMake]
  );

  const filterChips: FilterChip[] = useMemo(() => {
    return filterChipData
      .map(({ values, kind }) => createFilterChips(values, kind))
      .flat();
  }, [filterChipData, createFilterChips]);

  return (
    <>
      <article className="mb-[90px] flex flex-col gap-xl bg-neutral-0 px-l ml:px-0">
        <SearchFilterChipsSection
          updateFilter={updateFilter}
          filters={filters}
          clearFilters={clearFilters}
          onSortChange={onSortChange}
          sort={sort}
          data={data}
          filterChips={filterChips}
          handleUpdateFilter={handleUpdateFilter}
        />
        <div className="flex flex-col items-start gap-m ml:hidden">
          <div className="flex items-center gap-l">
            <h4 className="text-h3Regular text-neutral-900">Filters</h4>
            {totalFilterChips > 0 && (
              <div className="flex h-xl w-xl items-center justify-center rounded-full bg-blue-light p-xs text-badgeAlert text-blue-medium">
                {totalFilterChips}
              </div>
            )}
          </div>
          {filterChips.length > 0 && (
            <div
              className="flex flex-wrap gap-l overflow-hidden"
              ref={filterChipsRef}
            >
              {filterChips.map((chip, index) => {
                return (
                  <Badge
                    key={`${chip.label}_${index}`}
                    onClick={chip.onClose}
                    variant={chip.variant}
                    label={chip.label}
                    iconPosition="right"
                    secondaryIcon={
                      chip.filterName === 'postal' ? (
                        <MapPinIcon className="h-l w-l" />
                      ) : undefined
                    }
                    icon={
                      <XMarkIcon width={20} height={20} strokeWidth={1.5} />
                    }
                  />
                );
              })}
            </div>
          )}
        </div>
        {filtersAccordionGroup.map((filterItem, index) => {
          if (filterItem.condition) {
            return (
              <div key={`${filterItem.title}_${index}`}>
                <Accordion
                  className="mb-xl"
                  buttonClassName="!items-end"
                  title={
                    <div className="flex items-center gap-l text-body1Regular text-neutral-800 ml:text-body1Medium">
                      <div>{filterItem.title}</div>
                      {filterItem.title === 'EV Savings' && <EVSavingsToggle />}

                      {filterItem.numberOfChips > 0 && (
                        <div className="flex h-xl w-xl items-center justify-center rounded-full bg-blue-light p-xs text-badgeAlert text-blue-medium">
                          {filterItem.numberOfChips}
                        </div>
                      )}
                    </div>
                  }
                  expanded={
                    !vehicleFilterSettings.evSavings &&
                    filterItem.title === 'EV Savings'
                      ? false
                      : filterItem.expanded
                  }
                  setExpanded={() => {
                    handleSetExpanded(filterItem.title as ExpandedKey);
                  }}
                  icon={
                    !vehicleFilterSettings.evSavings &&
                    filterItem.title === 'EV Savings'
                      ? null
                      : { id: 'chevron', variant: 'small' }
                  }
                >
                  {filterItem.children}
                </Accordion>
                <hr className="mt-l border-[1px] border-neutral-200" />
              </div>
            );
          }
          return null;
        })}
      </article>
      <div className="safe-bottom fixed bottom-0 z-10 w-full justify-center pb-xl ml:hidden">
        <div className="flex w-full px-m m:px-0">
          <Button
            aria-label="Apply filters"
            onClick={onSearch}
            variant={ButtonVariants.Tertiary}
            background="bg-blue-light"
            shadow={'shadow-ml'}
          >
            Apply filters
          </Button>
        </div>
      </div>
    </>
  );
};

export default SearchFilters;
