import PaginationRanges from '@/components/atoms/Pagination/Pagination';
import Grid from '@/components/atoms/legacy/Grid';
import Heading, { HeadingLevels } from '@/components/atoms/legacy/Heading';
import TextButton from '@/components/atoms/legacy/TextButton';
import EmptySearchResult from '@/components/molecules/EmptySearchResult';
import { InventoryPageHeader } from '@/components/organisms/InventoryPageHeader/InventoryPageHeader';
import { LoadingProductListingLink } from '@/components/organisms/LoadingCards/LoadingCards';
import { ProductListingLink } from '@/components/organisms/ProductListingLink/ProductListingLink';
import SearchFilters, {
  SearchFiltersProps,
} from '@/components/organisms/SearchFilters';
import { VLPCustomOrderCardList } from '@/components/organisms/VLPCustomOrderCardList/VLPCustomOrderCardList';
import { SeoEnrichmentContent } from '@/components/template/SearchTemplate/SeoEnrichmentContent';
import { initialSavedVehicles } from '@/context/SavedVehicles';
import { useVehicleFilterSettingsContext } from '@/context/VehicleFilterSettings';
import { useZipContext } from '@/context/ZipContext';
import { useAuth } from '@/hooks/useAuth';
import { useLockScroll } from '@/hooks/useLockScroll';
import usePreviousPageData from '@/hooks/usePreviousPageData';
import useWindowSize from '@/hooks/useWindowSize';
import {
  DEFAULT_COSTS,
  DEFAULT_US_ZIP_CODE,
  EMPTY_FILTERS,
  GAS_SAVINGS_INITIAL_TERM,
  HIDDEN_MAKES,
  SEARCH_CONSTANTS,
  VLP_PRICE_DISCLAIMER,
} from '@/lib/constants';
import { formatNumberWithCommas } from '@/lib/formatNumberWithCommas';
import { getFuelCosts } from '@/lib/fuelCost/getFuelCosts';
import {
  getTotalIncentiveAmount,
  getTotalRebateAmount,
  returnPurchaseFederalIncentive,
} from '@/lib/incentiveUtils';
import { parseListingVehicle } from '@/lib/parseListingVehicle';
import {
  FetchProductsResult,
  VehicleListingModel,
} from '@/lib/schema/inventory/types';
import { BodyTypesByMakes } from '@/lib/schema/shared/types';
import { getUserAuthHeader } from '@/lib/schema/user/client-fetch';
import { mapFilterValuesToQueryParamsString } from '@/lib/searchFilters';
import { SearchType } from '@/lib/seo/seoTags';
import {
  FilterState,
  FilterType,
  UpdateFilterActionFields,
} from '@/types/filters';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import clsx from 'clsx';
import Head from 'next/head';
import { useRouter } from 'next/router';
import React, { useCallback, useMemo } from 'react';
import { ItemList, ListItem, WithContext } from 'schema-dts';
import { City } from '../../../lib/schema/geo/types';
import { SeoLocation } from '../../../lib/staticQueries';
import { RelatedLinks } from './RelatedLinks';

export const filterToPath = (
  filters: FilterType,
  sort: string,
  pageNumber?: number
) => {
  const ret: string[] = ['/search'];

  const newFilters = { ...filters, sort, page: pageNumber?.toString() };
  if ((sort === 'price_asc' || sort === 'price_desc') && !filters.price_start) {
    newFilters.price_start = '1';
  }

  Object.keys(newFilters).forEach((filter) => {
    const value = newFilters[filter as keyof FilterType];

    if (
      (typeof value === 'string' && value !== '') ||
      typeof value === 'boolean'
    ) {
      ret.push(
        `${encodeURIComponent(filter)}/${encodeURIComponent(value as string)}`
      );
    } else if (Array.isArray(value) && value.length > 0) {
      ret.push(
        `${encodeURIComponent(filter)}/${encodeURIComponent(value.join(','))}`
      );
    }
  });

  const urlQuery = ret.join('/');
  return urlQuery;
};

interface FilterAction {
  shouldRefetch?: boolean;
  fields: Partial<FilterType>;
}

const reducer = (state: FilterState, action: FilterAction) => {
  const { fields: updatedFields, shouldRefetch = false } = action;

  return {
    ...state,
    fields: {
      ...state.fields,
      ...updatedFields,
    },
    shouldRefetch,
  };
};

export interface SearchTemplateSeoData {
  /** Extra content to be shown for SEO, depending on the URL of the search page. See https://ev-com.atlassian.net/browse/EVCOM-2291 */
  enrichmentContent: {
    title: string;
    content: string;
  } | null;
  location: SeoLocation | null;
  topCities: City[];
  citiesInSameState: City[];
}

export interface SearchTemplateProps {
  searchType: SearchType;
  initialFilters: FilterType;
  initialSort: string;
  h1Tag: string;
  showNewCheckAvailabilityButton: boolean;
  autoCompleteResults: {
    searchMakes: string[];
    searchModels: Record<string, string[]>;
  };
  showRebates: boolean;
  makesBodyTypes?: BodyTypesByMakes | null;
  seoData: SearchTemplateSeoData | null;
}

const SearchTemplate: React.FC<SearchTemplateProps> = ({
  searchType,
  initialFilters,
  initialSort = '',
  h1Tag,
  showNewCheckAvailabilityButton,
  autoCompleteResults,
  makesBodyTypes = null,
  seoData,
}) => {
  const router = useRouter();
  const { user } = useAuth();
  const {
    settings: { isListedPrice, displayIncentives, displayFuelSavings },
    handleSetIsListedPrice: setIsListedPrice,
    handleSetEvSavings,
  } = useVehicleFilterSettingsContext();
  const { isGridView, setIsGridView } = usePreviousPageData({ isListedPrice });
  const [panelOpen, setPanelOpen] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(
    initialFilters.skip / SEARCH_CONSTANTS.pageSize + 1
  );

  const [sort, setSort] = React.useState(initialSort);
  const [listingSchema, setListingSchema] = React.useState<
    WithContext<ItemList>
  >({
    '@context': 'https://schema.org',
    '@type': 'ItemList',
    name: h1Tag,
    itemListElement: [],
  });

  const prevFilter = React.useRef(initialFilters);
  const prevSort = React.useRef(initialSort);
  const inventoryListingContainerRef = React.useRef<HTMLDivElement>(null);
  const productListingLinkRef = React.useRef<HTMLDivElement>(null);
  const searchFiltersRef = React.useRef<HTMLDivElement>(null);
  const { isDesktop, isMobile, isML } = useWindowSize();
  const queryClient = useQueryClient();

  const { preferredZip: zip } = useZipContext();

  const [filters, dispatch] = React.useReducer(reducer, {
    fields: {
      ...initialFilters,
      postal: initialFilters.postal || zip,
      incentive_zip: zip,
    },
    shouldRefetch: false,
  });

  const [basePath, setBasePath] = React.useState(
    filterToPath(filters.fields, sort)
  );
  const prevBasePath = React.useRef(filterToPath(filters.fields, sort));

  const updatePath = useCallback(
    (
      overrides?: { filterOverride?: FilterType; sortOverride?: string },
      pageNumber = 1
    ) => {
      const newFilters = overrides?.filterOverride ?? filters.fields;
      const newSort = overrides?.sortOverride ?? sort;
      const newBasePath =
        pageNumber === 1
          ? filterToPath(newFilters, newSort)
          : filterToPath(newFilters, newSort, pageNumber);

      setBasePath(newBasePath);
    },
    [filters, sort]
  );

  const handlePageClick = useCallback(
    (index: number) => {
      updatePath(undefined, index);
      setCurrentPage(index);
      window.scrollTo({ top: 0 });
    },
    [updatePath]
  );

  const updateFilter = useCallback(
    ({
      fields: newFilters,
      refetchOnUpdate = true,
    }: UpdateFilterActionFields) => {
      const shouldRefetch = refetchOnUpdate && !panelOpen;
      dispatch({
        fields: newFilters,
        shouldRefetch,
      });

      if (shouldRefetch) {
        handlePageClick(1);
        updatePath({
          filterOverride: {
            ...filters.fields,
            ...newFilters,
          },
        });
      }
    },
    [panelOpen, updatePath, filters, handlePageClick]
  );

  const clearFilters = useCallback(() => {
    handleSetEvSavings(true);
    handlePageClick(1);
    const newFilters: FilterType = EMPTY_FILTERS;
    newFilters.postal = zip;

    dispatch({
      fields: newFilters,
      shouldRefetch: !panelOpen,
    });
    if (!panelOpen) {
      updatePath({
        filterOverride: EMPTY_FILTERS,
      });
    }
    window.scrollTo({
      top: 0,
    });
  }, [zip, handleSetEvSavings, handlePageClick, panelOpen, updatePath]);

  const getCustomOrderData = useCallback(async () => {
    const { make: customMakes, model: customModels, body } = filters.fields;
    const filteredCustomMakes = customMakes.filter(
      (make) => !HIDDEN_MAKES.includes(make)
    );
    if (filteredCustomMakes.length === 0) {
      return [];
    }
    const customOrderResponse = await fetch(
      `/api/inventory/listings/customOrderBuilds`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          makes: filteredCustomMakes,
          modelsList: customModels,
          price: filters.fields.price_end,
          zip: zip || DEFAULT_US_ZIP_CODE,
          bodyTypes: body,
        }),
      }
    );

    if (customOrderResponse.ok) {
      const customOrderResponseJson = await customOrderResponse.json();
      return customOrderResponseJson;
    }
    return [];
  }, [filters.fields, zip]);

  const fetchProducts = useCallback(
    async (page: number): Promise<FetchProductsResult> => {
      const skip = SEARCH_CONSTANTS.pageSize * (page - 1);

      const filtersForFetch: FilterType = {
        ...filters.fields,
        postal: filters.fields.postal || zip,
        limit: SEARCH_CONSTANTS.pageSize,
        skip,
        sort,
        incentive_zip: zip,
      };

      const searchBody = {
        ...filtersForFetch,
        skip,
        limit: SEARCH_CONSTANTS.pageSize,
        sort,
      };

      const queryString = mapFilterValuesToQueryParamsString(searchBody);

      let response;
      if (searchType === 'SEO') {
        response = await fetch(`/api/inventory/seo/?${queryString}`);
      } else {
        response = await fetch(`/api/inventory/listings/?${queryString}`);
      }

      if (!response.ok) {
        throw new Error('Failed to fetch products');
      }

      const {
        content,
        size,
        total,
        has_exact_match: hasExactMatch,
      }: {
        content: VehicleListingModel[];
        size: number;
        total: number;
        has_exact_match: boolean;
      } = await response.json();

      return {
        content: content.map((product: VehicleListingModel) =>
          parseListingVehicle(product)
        ),
        size,
        total,
        hasExactMatch,
      };
    },
    [filters.fields, sort, searchType, zip]
  );

  const fetchSavedVehicles = useCallback(async () => {
    if (!user) {
      return initialSavedVehicles;
    }
    const authHeader = await getUserAuthHeader();
    if (!authHeader) {
      throw new Error("Couldn't fetch saved vehicles");
    }
    const response = await fetch('/api/user/user_store', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: authHeader,
      },
    });
    if (response.ok) {
      const result = await response.json();
      return result;
    }
    throw new Error("Couldn't fetch saved vehicles");
  }, [user]);

  const { data: savedVehicles } = useQuery({
    queryKey: ['savedVehicles', user],
    queryFn: fetchSavedVehicles,
    throwOnError: false,
  });

  const { data: makes } = useQuery({
    queryKey: ['makes', autoCompleteResults],
    queryFn: async () => {
      let makesJson: string[] = [];
      if (autoCompleteResults.searchMakes.length > 0) {
        makesJson = autoCompleteResults.searchMakes;
      } else {
        const makesResponse = await fetch('/api/inventory/listings/makes');
        if (!makesResponse.ok) {
          return [];
        }
        makesJson = await makesResponse.json();
      }
      return makesJson.map((makeName) => ({
        text: makeName,
        value: makeName,
      }));
    },
  });

  const { data: modelsByMake } = useQuery({
    queryKey: [filters.fields.make],
    queryFn: async () => {
      const response = await fetch(
        `/api/inventory/listings/models?makes=${filters.fields.make.join(',')}`
      );
      if (response.ok) {
        return response.json() as Promise<Record<string, string[]>>;
      }
      throw new Error();
    },
    enabled: filters.fields.make.length > 0,
  });

  const selectableModels = useMemo(
    () =>
      modelsByMake &&
      Object.values(modelsByMake)
        .flat()
        .sort((a, b) => a.localeCompare(b)),
    [modelsByMake]
  );

  const filtersAsChips: SearchFiltersProps['filterChipData'] = useMemo(() => {
    return [
      {
        kind: 'make',
        values: filters.fields.make,
      },
      {
        kind: 'model',
        values: filters.fields.model.filter((modelName) =>
          selectableModels?.includes(modelName)
        ),
      },
      {
        kind: 'body',
        values: filters.fields.body,
      },
      {
        kind: 'features',
        values: filters.fields.features,
      },
      {
        kind: 'condition',
        values: filters.fields.condition
          ? filters.fields.condition.split(',')
          : [],
      },
      {
        kind: 'fuelType',
        values: filters.fields.fuelType
          ? filters.fields.fuelType.split(',')
          : [],
      },
      {
        kind: 'vector_search',
        values: filters.fields.vector_search
          ? [filters.fields.vector_search]
          : [],
      },
      {
        kind: 'price_start',
        values:
          filters.fields.price_start && filters.fields.price_start !== '0'
            ? [`Min: $${formatNumberWithCommas(filters.fields.price_start)}`]
            : [],
      },
      {
        kind: 'price_end',
        values:
          filters.fields.price_end && filters.fields.price_end !== '250000'
            ? [`Max: $${formatNumberWithCommas(filters.fields.price_end)}`]
            : [],
      },
    ];
  }, [selectableModels, filters]);

  const activeFilterCount = useMemo(() => {
    return filtersAsChips.reduce(
      (count, chip) => count + chip.values.length,
      0
    );
  }, [filtersAsChips]);

  const { data: features } = useQuery({
    queryKey: ['features'],
    queryFn: async () => {
      if (process.env.NEXT_PUBLIC_ENABLE_BODY_FEATURE_FILTER !== 'true') {
        return [];
      }
      const featureResponse = await fetch('/api/inventory/listing/features');

      if (!featureResponse.ok) {
        return [];
      }

      const featureJson: string[] = await featureResponse.json();

      return featureJson.map((featureName) => ({
        text: featureName,
        value: featureName,
      }));
    },
  });

  const {
    data,
    isError: isDataFetchingFailed,
    isFetching,
  } = useQuery({
    queryKey: ['products', currentPage, filters],
    queryFn: () => fetchProducts(currentPage),
    refetchOnWindowFocus: false,
  });

  const refetchData = useCallback(() => {
    queryClient.resetQueries({ queryKey: ['products'], exact: true });
  }, [queryClient]);

  const handleFilterSearch = useCallback(() => {
    setPanelOpen(false);
    updatePath();
    refetchData();
  }, [updatePath, refetchData]);

  const showEmptyResults =
    !!data &&
    (data.total === 0 ||
      currentPage === Math.ceil(data.total / SEARCH_CONSTANTS.pageSize));
  const { data: customOrderCardsData } = useQuery({
    queryKey: ['customOrderData', filters],
    queryFn: getCustomOrderData,
    enabled: showEmptyResults,
  });

  React.useEffect(() => {
    if (!isDesktop) {
      setIsGridView(true);
    }
  }, [isDesktop, setIsGridView]);

  const vehicleCardList = useMemo(() => {
    const emptyArray = Array.from(
      { length: SEARCH_CONSTANTS.pageSize },
      (_, i) => i
    );
    let cardSection: React.ReactNode =
      !isDataFetchingFailed &&
      isFetching &&
      emptyArray.map((_, i) => (
        <div
          className={`flex ${isGridView ? 'h-[600px] s:h-[780px] m:h-[620px] ml:h-[590px]' : 'h-[300px]'}`}
          key={i}
        >
          <LoadingProductListingLink isColumnLayout={isGridView} />
        </div>
      ));
    if (data) {
      let sortedListings = data.content;
      // Sort page products after evSavings are applied
      if (
        filters.fields.sort === 'price_asc' ||
        filters.fields.sort === 'price_desc'
      ) {
        sortedListings = sortedListings.sort((a, b) => {
          let totalAPrice = a.price.value || 0;
          let totalBPrice = b.price.value || 0;

          if (displayFuelSavings) {
            const { totalSavings: totalSavingsA } = getFuelCosts({
              carType: a.body,
              electricityCost: Number(DEFAULT_COSTS.electricityCost),
              gasolineCost: Number(DEFAULT_COSTS.gasolineCost),
              mileagePerYear: Number(DEFAULT_COSTS.mileagePerYear),
              years: Number(GAS_SAVINGS_INITIAL_TERM),
              fuelType: a.fuelType === 'Hybrid' ? 'Hybrid' : 'Electric',
            });
            const { totalSavings: totalSavingsB } = getFuelCosts({
              carType: b.body,
              electricityCost: Number(DEFAULT_COSTS.electricityCost),
              gasolineCost: Number(DEFAULT_COSTS.gasolineCost),
              mileagePerYear: Number(DEFAULT_COSTS.mileagePerYear),
              years: Number(GAS_SAVINGS_INITIAL_TERM),
              fuelType: b.fuelType === 'Hybrid' ? 'Hybrid' : 'Electric',
            });
            totalAPrice -= totalSavingsA;
            totalBPrice -= totalSavingsB;
          }
          if (displayIncentives) {
            const allIncentiveA = [
              ...(a.incentives?.State || []),
              ...(a.incentives?.local || []),
              ...returnPurchaseFederalIncentive(a.incentives?.Federal || []),
            ];
            const allIncentiveB = [
              ...(b.incentives?.State || []),
              ...(b.incentives?.local || []),
              ...returnPurchaseFederalIncentive(b.incentives?.Federal || []),
            ];
            const monetaryIncentiveAmountA =
              getTotalIncentiveAmount(allIncentiveA);
            const rebateAmountA = getTotalRebateAmount(allIncentiveA);
            const monetaryIncentiveAmountB =
              getTotalIncentiveAmount(allIncentiveB);
            const rebateAmountB = getTotalRebateAmount(allIncentiveB);

            totalAPrice -= monetaryIncentiveAmountA + rebateAmountA;
            totalBPrice -= monetaryIncentiveAmountB + rebateAmountB;
          }

          if (filters.fields.sort === 'price_asc') {
            return Math.max(totalAPrice, 0) - Math.max(totalBPrice, 0);
          }
          return Math.max(totalBPrice, 0) - Math.max(totalAPrice, 0);
        });
      }

      cardSection = sortedListings.map((product, i) => {
        const vehicleId = product.listingId;
        const isSaved = !!savedVehicles?.listing?.[vehicleId];
        const contactedDealer =
          !!savedVehicles?.listing?.[vehicleId]?.contact_dealership;
        return (
          product.price.value && (
            <ProductListingLink
              key={`${product.dealer?.dealerID}_${i}`}
              ref={productListingLinkRef}
              product={product}
              showNewCheckAvailabilityButton={showNewCheckAvailabilityButton}
              isColumnLayout={isGridView}
              isSaved={isSaved}
              isListedPrice={isListedPrice}
              isMobile={isMobile}
              filters={filters}
              contactedDealer={contactedDealer}
            />
          )
        );
      });
    }
    return cardSection;
  }, [
    isDataFetchingFailed,
    isGridView,
    showNewCheckAvailabilityButton,
    data,
    isFetching,
    savedVehicles?.listing,
    isListedPrice,
    isMobile,
    filters,
    displayFuelSavings,
    displayIncentives,
  ]);

  let pageCount = 0;
  if (data) {
    pageCount = Math.ceil(data.total / SEARCH_CONSTANTS.pageSize);
  }

  const onSortChange = useCallback(
    (newSort: string) => {
      setSort(newSort);
      updateFilter({ fields: { sort: newSort } });
      updatePath({ sortOverride: newSort });
    },
    [updateFilter, updatePath]
  );

  React.useEffect(() => {
    if (basePath !== prevBasePath.current) {
      router.push(basePath, undefined, { shallow: true });
      prevBasePath.current = basePath;
    }
  }, [router, basePath]);

  React.useEffect(() => {
    if (data) {
      const schemaList: ListItem[] = data.content.map((product, index) => ({
        '@type': 'ListItem',
        position: index + 1,
        item: {
          '@type': 'ListItem',
          name: `${product.year} ${product.make} ${product.model}`,
          url: `${process.env.NEXT_PUBLIC_REDIRECTION}/vehicle/${product.shortListingId}`,
          image: product.images[0],
        },
      }));
      setListingSchema((prev) => ({ ...prev, itemListElement: schemaList }));
    }
  }, [data]);

  React.useEffect(() => {
    const sortChanged = sort !== prevSort.current;

    if (sortChanged || filters.shouldRefetch) {
      refetchData();
      prevSort.current = sort;
      prevFilter.current = filters.fields;
    }
  }, [filters, sort, fetchProducts, refetchData]);

  React.useEffect(() => {
    const addressField = document.getElementById(
      'address-input'
    ) as HTMLInputElement;

    const searchFiltersContainer = searchFiltersRef.current;

    const handleScroll = () => {
      if (addressField && document.activeElement === addressField) {
        addressField.blur();
      }
    };

    searchFiltersContainer?.addEventListener('scroll', handleScroll);
    window.addEventListener('scroll', handleScroll);

    return () => {
      searchFiltersContainer?.removeEventListener('scroll', handleScroll);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [searchFiltersRef]);

  React.useEffect(() => {
    if (filters.fields.incentive_zip !== zip) {
      updateFilter({ fields: { incentive_zip: zip }, refetchOnUpdate: false });
    }
  }, [zip, filters.fields.incentive_zip, updateFilter]);

  useLockScroll(panelOpen);

  return (
    <>
      <Head>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(listingSchema) }}
        />
      </Head>
      <div
        className="sticky top-0 z-20 border-b-[1px] bg-header-gradient backdrop-blur-header"
        data-testid="inventory page header"
      >
        <div className="mx-auto flex max-w-[1920px] items-center justify-between px-l py-s md:px-[40px] ml:py-l l:px-[60px] xl:px-[100px]">
          <InventoryPageHeader
            autoCompleteResults={autoCompleteResults}
            h1Tag={h1Tag}
            updateFilter={updateFilter}
            setIsGridView={setIsGridView}
            isGridView={isGridView}
            onSortChange={onSortChange}
            sort={sort}
            data={data}
            filters={filters.fields}
            isML={isML}
            isDesktop={isDesktop}
            setPanelOpen={setPanelOpen}
            filterCount={activeFilterCount}
            updatePath={updatePath}
            setSort={setSort}
          />
        </div>
      </div>

      <div className="mx-auto max-w-[1920px] px-0 xl:px-[40px]">
        <Grid className="gap-x-xl bg-neutral-100 md:grid-cols-10">
          <section
            ref={searchFiltersRef}
            data-testid="search filters section"
            className={`${
              panelOpen ? '' : 'hidden min-w-[300px] max-w-[350px] ml:flex'
            } hide-scrollbar fixed bottom-0 left-0 right-0 top-0 z-20 flex flex-col overflow-auto bg-neutral-0 px-0 m:px-[40px] ml:sticky ml:top-[60px] ml:z-10 ml:col-span-3 ml:h-screen ml:!pt-xl ml:pl-[40px] ml:pr-xl l:pl-[60px]`}
          >
            <div className="flex flex-grow flex-col ml:p-0 ml:pb-[140px]">
              <section className="sticky top-0 z-50 mb-l flex flex-row items-center justify-between border-b border-neutral-200 bg-neutral-0 p-xl pb-l text-right ml:hidden">
                <div className="flex items-center">
                  <button
                    className="ml-auto rounded-[4px] p-s"
                    onClick={handleFilterSearch}
                  >
                    <XMarkIcon className="size-xl stroke-2 text-neutral-800" />
                  </button>
                  <Heading
                    className="text-h3SemiBold text-neutral-900"
                    level={HeadingLevels.H4}
                  >
                    Refine search
                  </Heading>
                </div>
                <TextButton
                  className="px-s text-body2Regular text-blue-medium"
                  onClick={() => {
                    clearFilters();
                  }}
                >
                  Reset
                </TextButton>
              </section>
              <SearchFilters
                filters={filters.fields}
                makes={makes || []}
                features={features || []}
                updateFilter={updateFilter}
                clearFilters={clearFilters}
                onSearch={handleFilterSearch}
                setIsListedPrice={setIsListedPrice}
                isListedPrice={isListedPrice}
                setIsGridView={setIsGridView}
                isGridView={isGridView}
                onSortChange={onSortChange}
                sort={sort}
                data={data}
                makesBodyTypes={makesBodyTypes}
                models={{
                  byMake: modelsByMake ?? {},
                  flat: selectableModels ?? [],
                }}
                filterChipData={filtersAsChips}
              />
            </div>
          </section>
          <section
            className="ml:scrollbarHide col-span-full p-l m:px-3xl ml:col-span-9 ml:col-start-4 ml:overflow-auto ml:pl-0 l:pr-[64px]"
            ref={inventoryListingContainerRef}
          >
            <div className="flex h-full flex-col">
              <section className={`flex-col space-y-xl`}>
                {data && data.total > 0 && data.hasExactMatch === false && (
                  <div className="flex flex-col items-center justify-center gap-s px-[40px] py-xl text-neutral-800">
                    <div className="text-h4Regular">
                      You&apos;ve reached the end of the search results.
                    </div>
                    <div className="text-body1Regular">
                      Here are some more vehicles that might interest you
                    </div>
                  </div>
                )}
                {isDataFetchingFailed && (
                  <div className="flex min-h-full flex-col justify-center">
                    <EmptySearchResult
                      aria-label="try search again"
                      variant="failedSearch"
                      headerText="Something went wrong."
                      bodyText="We encountered an issue displaying vehicle listings."
                      handleResetAction={refetchData}
                    />
                  </div>
                )}
                <div
                  className={`grid grid-cols-1 gap-xl ${
                    isGridView ? 'md:grid-cols-2 l:grid-cols-3' : ''
                  }`}
                >
                  {vehicleCardList}
                </div>

                {!isDataFetchingFailed && showEmptyResults && (
                  <div
                    className={clsx('flex flex-col justify-center', {
                      'pt-3xl': customOrderCardsData?.length === 0,
                    })}
                  >
                    <EmptySearchResult
                      aria-label="reset filters"
                      variant="noResults"
                      headerText="You've searched all available vehicles"
                      handleResetAction={clearFilters}
                      handleExpandSearchAction={() => {
                        updateFilter({ fields: { distance: 'nationwide' } });
                      }}
                    />

                    {customOrderCardsData?.length > 0 && (
                      <VLPCustomOrderCardList
                        cardData={customOrderCardsData}
                        isGridView={isGridView}
                        isListedPrice={isListedPrice}
                        location={zip}
                        savedVehicles={savedVehicles}
                        isMobile={isMobile}
                      />
                    )}
                  </div>
                )}
                {data && data.total > SEARCH_CONSTANTS.pageSize && (
                  <div className="flex w-full flex-col items-center justify-center gap-xl py-xl">
                    <PaginationRanges
                      onChange={(
                        _event: React.ChangeEvent<unknown>,
                        index: number
                      ) => handlePageClick(index)}
                      count={pageCount}
                      page={currentPage}
                      size="large"
                      handlePreviousPage={() =>
                        handlePageClick(currentPage - 1)
                      }
                      handleNextPage={() => handlePageClick(currentPage + 1)}
                    />
                  </div>
                )}
              </section>
              <section className="flex flex-col gap-l p-l m:px-[60px]">
                <p className="text-microLight text-neutral-800" data-nosnippet>
                  {VLP_PRICE_DISCLAIMER}
                </p>
                {!!seoData?.enrichmentContent && !!data && (
                  <SeoEnrichmentContent
                    {...seoData.enrichmentContent}
                    listings={data.content}
                    totalCars={data.total}
                  />
                )}
              </section>
            </div>
          </section>
        </Grid>
      </div>
      {!!seoData && (
        <RelatedLinks
          filters={filters.fields}
          modelsByMake={modelsByMake ?? {}}
          seoLocation={seoData.location}
          topCities={seoData.topCities}
          citiesInSameState={seoData.citiesInSameState}
        />
      )}
    </>
  );
};

export default SearchTemplate;
